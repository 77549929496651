<template>
  <RouterLink
    :to="targetRoute.href"
    class="hover:underline"
    :class="{
      'text-sm font-semibold leading-6 text-on-primary': !isMobile,
      'text-base -mx-3 block rounded-lg px-3 py-2 font-semibold leading-7 text-on-primary hover:bg-primary':
        isMobile,
    }"
  >
    {{ label ?? t(`pages.${targetRoute.name as string}`) }}
  </RouterLink>
</template>

<script lang="ts" setup>
import type { RouteRecord } from '@/types';

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { resolveRoute } from '@/utils/router';

type Props = {
  route: RouteRecord;
  label?: string;
  isMobile?: boolean;
  employeeId?: string;
};

const { t } = useI18n();

const props = withDefaults(defineProps<Props>(), { isMobile: false });

const targetRoute = computed(() => resolveRoute(props.route, props.employeeId));
</script>
