<template>
  <header class="relative z-10 bg-black">
    <nav
      class="mx-auto flex min-h-[56px] max-w-screen-xl items-center justify-between px-6 py-2"
      aria-label="Main navigation"
    >
      <div class="flex items-center justify-between gap-x-12 lg:w-full">
        <RouterLink
          :to="`/employee/${userStore.ownEmployeeId}/`"
          :style="{ pointerEvents: userStore.ownEmployeeId ? 'auto' : 'none' }"
          class="-m-1.5 p-1.5"
        >
          <span class="sr-only">Eidra</span>
          <img class="h-auto w-16" src="@/assets/eidra-logo-white.svg" alt="Eidra" />
        </RouterLink>

        <div v-if="isOwnUserFound" class="hidden lg:flex lg:flex-grow lg:gap-x-12">
          <NavigationItem v-for="route in navigationItems" :key="route.name" :route="route" />
        </div>

        <div class="flex gap-x-12">
          <Search v-if="isOwnUserFound" class="hidden lg:block" placeholder="Search" omnisearch />

          <Menu
            v-if="userStore.isAuthenticated"
            as="div"
            className="relative text-left hidden lg:block"
          >
            <MenuButton class="block">
              <Avatar
                label="My account"
                :name="userStore.name"
                :profile-picture="userStore.picture"
              />
            </MenuButton>
            <transition
              leave-active-class="transition ease-in duration-100"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-4 w-52 overflow-auto rounded-md bg-surface shadow-lg focus:outline-none"
              >
                <MenuItem v-if="isOwnUserFound" v-slot="{ active }">
                  <a
                    class="text-sm group flex w-full items-center gap-x-3 rounded-md px-2 py-2"
                    :class="active ? 'bg-primary text-on-primary' : 'bg-surface text-on-surface'"
                    :href="`/employee/${userStore.ownEmployeeId}/`"
                  >
                    <component :is="UserIcon" class="h-6 w-6" aria-hidden="true" />
                    <span>{{ t('navigation.myAccount') }}</span>
                  </a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <button
                    class="text-sm group flex w-full items-center gap-x-3 rounded-md px-2 py-2"
                    :class="active ? 'bg-primary text-on-primary' : 'bg-surface text-on-surface'"
                    @click="logout"
                  >
                    <component :is="ArrowRightOnRectangleIcon" class="h-6 w-6" aria-hidden="true" />
                    <span>{{ t('navigation.logout') }}</span>
                  </button>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
      <div class="flex lg:hidden">
        <button
          type="button"
          class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-on-primary"
          @click="openMobileMenu"
        >
          <span class="sr-only">Open main menu</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </nav>

    <!-- Mobile menu, show/hide based on menu open state. -->
    <Dialog as="div" class="lg:hidden" @close="closeMobileMenu" :open="mobileMenuOpen">
      <!-- Background backdrop, show/hide based on slide-over state. -->
      <div class="fixed inset-0 z-10"></div>
      <DialogPanel
        class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-primary px-6 sm:max-w-sm sm:ring-1"
      >
        <div class="flex min-h-[56px] items-center justify-between">
          <a href="#" class="-m-1.5 p-1.5">
            <span class="sr-only">Eidra</span>
            <img class="h-auto w-16" src="@/assets/eidra-logo-white.svg" alt="Eidra" />
          </a>

          <button
            type="button"
            class="-m-2.5 rounded-md p-2.5 text-on-primary"
            @click="closeMobileMenu"
          >
            <span class="sr-only">Close menu</span>
            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-on-primary">
            <div v-if="isOwnUserFound" class="space-y-2 py-6">
              <NavigationItem
                v-for="route in navigationItems"
                :key="route.name"
                :route="route"
                is-mobile
              />
            </div>

            <div class="space-y-2 py-6">
              <Avatar :name="userStore.name" :profile-picture="userStore.picture" />
              <NavigationItem
                v-if="isOwnUserFound"
                :route="{ path: `/employee/:employeeId`, name: 'personalData' } as RouteRecord"
                :employee-id="userStore.ownEmployeeId"
                :label="t('navigation.myAccount')"
                is-mobile
              />

              <button
                type="button"
                class="text-base -mx-3 block rounded-lg px-3 py-2 font-semibold leading-7 text-on-primary hover:bg-primary"
                @click="logout"
              >
                {{ t('navigation.logout') }}
              </button>
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  </header>
</template>

<script lang="ts" setup>
import type { RouteRecord } from '@/types';

import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/stores/user';
import { useEmployeeStore } from '@/stores/employee';
import { getStaticRoutes } from '@/utils';
import { Dialog, DialogPanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import {
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  UserIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';

import Avatar from '@/components/shared/Avatar.vue';
import NavigationItem from '@/components/NavigationItem.vue';
import Search from '@/components/shared/Search.vue';

const { ownPermissions } = storeToRefs(useEmployeeStore());
const userStore = useUserStore();
const { t } = useI18n();

const mobileMenuOpen = ref(false);

const openMobileMenu = () => (mobileMenuOpen.value = true);
const closeMobileMenu = () => (mobileMenuOpen.value = false);

const isOwnUserFound = computed(() => userStore.ownEmployeeId);

const navigationItems = computed(() => {
  const includeGlobalAuditTrail =
    ownPermissions.value?.roles?.write && ownPermissions.value?.scopes?.write;

  const includeTriggers = ownPermissions.value?._id?.write;

  return getStaticRoutes().filter((route) => {
    if (route.name === 'global-audit-trail' && includeGlobalAuditTrail) {
      return true;
    }

    if (route.name === 'triggers' && includeTriggers) {
      return true;
    }

    return route.meta.mainNavigation;
  });
});

function logout() {
  userStore.logout({ logoutParams: { returnTo: window.location.origin } });
}
</script>
