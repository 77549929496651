import gql from 'graphql-tag';

export const AddressFragment = gql`
  fragment AddressFragment on Address {
    addition
    city
    country
    number
    postalCode
    street
  }
`;

export const ContactFragment = gql`
  fragment ContactFragment on Contact {
    type
    value
  }
`;

export const MoneyFragment = gql`
  fragment MoneyFragment on Money {
    amount
    currency
  }
`;

export const RelationFragment = gql`
  fragment RelationFragment on Relation {
    firstName
    lastName
    relation
    contacts {
      ...ContactFragment
    }
  }
`;

export const EmployeeFragment = gql`
  fragment EmployeeFragment on Employee {
    absence {
      actualHours
      actualFtePercentage
      longTerm
      reason
      startDate
      expectedReturnDate
    }
    address {
      ...AddressFragment
    }
    age
    birthDate
    driveUrl
    email
    emailPersonal
    employeeId
    employmentType
    employmentStatus
    endDate
    firstName
    contractHours
    ftePercentage
    fullName
    gender
    iceContacts {
      ...RelationFragment
    }
    id
    identity {
      officialFirstName
      officialLastName
      identifier
      sex
    }
    internalAvatar
    lastName
    lastWorkingDay
    localPayrollId
    mentor {
      _id
      fullName
      email
      phonePersonal
    }
    organisationData {
      billable
      city
      costCenter
      country
      jobRole
      organisation
      legalEntity
      seniority
      title
      track
      skillTrack
      organisationSettings {
        companyCode
        fteHours
      }
    }
    phonePersonal
    privacy {
      employee {
        address {
          read
        }
        phonePersonal {
          read
        }
      }
      public {
        phonePersonal {
          read
        }
      }
    }
    probationPeriod
    probationPeriodEndDate
    reasonForLeaving
    roles
    salaryAndBenefits {
      actualSalary {
        ...MoneyFragment
      }
      basicSalary {
        ...MoneyFragment
      }
      vacationEntitlement
    }
    scopes
    slack {
      id
      image_72
      image_192
      username
    }
    startDate
  }
  ${AddressFragment}
  ${ContactFragment}
  ${MoneyFragment}
  ${RelationFragment}
`;
