<template>
  <div class="flex min-h-screen flex-col justify-between">
    <div class="flex flex-1 flex-col">
      <Navigation />
      <div class="flex flex-1 flex-wrap">
        <SideNavigation v-if="$route.matched.some((route) => route.path.includes('/employee/'))" />
        <main class="flex-1">
          <div
            class="fixed inset-0 z-10 grid-stack"
            :class="{ invisible: !employeeStore.isMutating }"
          >
            <div class="bg-on-background/20 grid-stack-child">
              <span aria-live="polite" class="invisible">
                {{ employeeStore.isMutating ? t('loading') : '' }}
              </span>
            </div>
          </div>
          <slot />
        </main>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script lang="ts" setup>
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useEmployeeStore } from '@/stores/employee';

import Navigation from '@/components/Navigation.vue';
import SideNavigation from '@/components/SideNavigation.vue';
import Footer from '@/components/shared/Footer.vue';

const employeeStore = useEmployeeStore();
const { t } = useI18n();

watch(
  () => employeeStore.isMutating,
  () => {
    if (employeeStore.isMutating) {
      window.addEventListener('keydown', handleTabKey);
    } else {
      window.removeEventListener('keydown', handleTabKey);
    }
  },
);

function handleTabKey(e: KeyboardEvent) {
  if (e.key === 'Tab') {
    e.preventDefault();
    console.warn('Tab key is disabled, because we are mutating the data');
  }
}
</script>
