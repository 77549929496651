import type { Maybe } from '@/types/generated/graphql';

import { computed, watch } from 'vue';
import { defineStore } from 'pinia';
import { useAuth0 } from '@auth0/auth0-vue';
import { useEmployeeStore } from '@/stores/employee';
import rg4js from 'raygun4js';

export const useUserStore = defineStore('user', () => {
  const auth0 = useAuth0();
  const employeeStore = useEmployeeStore();

  watch(auth0.user, async (user) => {
    let setUserSettings;

    if (user) {
      setUserSettings = {
        identifier: auth0.user.value?.sub,
        isAnonymous: false,
        email: auth0.user.value?.email,
        fullName: auth0.user.value?.name,
        firstName: auth0.user.value?.given_name,
        lastName: auth0.user.value?.family_name,
      };
    } else {
      setUserSettings = {
        identifier: 'anonymous',
        isAnonymous: true,
      };
    }

    // @ts-expect-error
    rg4js('setUser', setUserSettings);
  });

  const email = computed<string | undefined>(() => auth0.user.value?.email);

  const ownEmployee = computed(() => employeeStore.ownEmployee);
  const ownEmployeeId = computed(() => employeeStore.ownEmployee?.id);
  const name = computed<string>(() => ownEmployee.value?.fullName ?? auth0.user.value?.name ?? '?');
  const picture = computed<Maybe<string> | undefined>(() => ownEmployee.value?.internalAvatar);

  return { ...auth0, email, name, picture, ownEmployeeId, ownEmployee };
});
