import gql from 'graphql-tag';
import { EmployeeFragment } from '@/graphql/fragments/employee';

export const createEmployeeMutation = gql`
  mutation createEmployee($employee: EmployeeInput!) {
    createEmployee(input: $employee) {
      id
      ...EmployeeFragment
    }
  }
  ${EmployeeFragment}
`;
