import type { CountryCallingCode, CountryCode as ICountryCode } from 'libphonenumber-js';

import { sortByName } from './sort';

export type CountryISO = ICountryCode;
export type CountryCode = CountryCallingCode;

export type Country = {
  name: string;
  iso: CountryISO;
};

export const DEFAULT_COUNTRY: Country = { name: 'The Netherlands', iso: 'NL' };

export const allCountries: Country[] = sortByName([
  { name: 'Denmark', iso: 'DK' },
  { name: 'The Netherlands', iso: 'NL' },
  { name: 'Norway', iso: 'NO' },
  { name: 'Sweden', iso: 'SE' },
  { name: 'United States', iso: 'US' },
]);

export function findCountryByISO(iso = ''): Country | undefined {
  return allCountries.find((country) => country.iso.toLowerCase() === iso.toLowerCase());
}

export function getCountryNameByISO(iso = ''): string {
  const country = allCountries.find((country) => country.iso.toLowerCase() === iso.toLowerCase());
  if (!country) {
    throw new Error(`Country with ISO ${iso} not supported`);
  }
  return country.name;
}

export function getAllCountryNames(): string[] {
  return allCountries.map((country) => country.name);
}

export function getAllCountryISOs(): CountryISO[] {
  return allCountries.map((country) => country.iso);
}
