<template>
  <div class="spinner align-center mx-auto flex justify-center p-24">
    <div class="svg-holder" v-for="n in paths.length" v-bind:key="n.toString()">
      <svg class="shadow-svg" viewBox="-50 0 340 60">
        <ellipse class="shadow" cx="120" cy="30" rx="0" ry="0" fill="black" />
      </svg>
      <div class="logo-holder">
        <svg
          class="logo-svg"
          :viewBox="paths[n - 1].viewBox"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
        >
          <path class="logo" fill="currentColor" :d="paths[n - 1].d" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const paths = [
  {
    d: 'M0 3.39014H97.41V34.1301H33.9V66.9001H89.5V94.9201H33.9V130.85H97.41V161.59H0V3.39014Z',
    viewBox: '-35 0 160 165',
  },
  {
    d: 'M0.160156 18.76C0.160156 8.59 8.30015 0 18.6902 0C29.0802 0 37.6702 8.59 37.6702 18.76C37.6702 28.93 29.0802 37.52 18.6902 37.52C8.30015 37.52 0.160156 29.16 0.160156 18.76ZM2.64015 49.49H35.1801V161.58H2.64015V49.49Z',
    viewBox: '-68 0 160 162',
  },
  {
    d: 'M0.259766 102.54C0.259766 68.4101 25.1198 43.5501 55.1798 43.5501C70.3198 43.5501 83.4298 49.6501 91.3398 61.1801V0.390137H123.88V158.59H91.3398V143.9C84.1098 155.43 70.3198 161.53 55.1798 161.53C25.1198 161.53 0.259766 136.67 0.259766 102.54ZM91.7898 102.32C91.7898 85.6001 78.9098 72.2601 62.4098 72.2601C45.9098 72.2601 33.2598 85.5901 33.2598 102.54C33.2598 119.49 46.1398 132.82 62.4098 132.82C78.6798 132.82 91.7898 119.26 91.7898 102.31V102.32Z',
    viewBox: '-16 0 160 162',
  },
  {
    d: 'M0.800781 0.490234H33.3408V20.1502C39.2208 7.04023 49.8408 0.490234 66.3408 0.490234H91.2008V30.7702H63.8508C44.1908 30.7702 33.3408 42.0702 33.3408 62.4102V112.58H0.800781V0.490234Z',
    viewBox: '-40 0 160 113',
  },
  {
    d: 'M0.259766 59.5398C0.259766 25.4098 25.1197 0.549805 55.1797 0.549805C70.0997 0.549805 83.4298 6.64981 91.3398 18.1798V3.48981H123.88V115.58H91.3398V100.89C84.1098 112.42 70.0997 118.52 55.1797 118.52C25.1197 118.52 0.259766 93.6598 0.259766 59.5298V59.5398ZM91.7898 59.3198C91.7898 42.5998 78.9098 29.2598 62.4098 29.2598C45.9098 29.2598 33.2598 42.5898 33.2598 59.5398C33.2598 76.4898 46.1398 89.8198 62.4098 89.8198C78.6798 89.8198 91.7898 76.2598 91.7898 59.3098V59.3198Z',
    viewBox: '-20 0 160 119',
  },
];
</script>

<style lang="css" scoped>
.spinner {
  --timing: 500ms infinite ease-in-out;
  --delay: 50ms;
}

.svg-holder {
  width: 30px;
  margin: 0 5px;
  display: flex;
  flex-direction: column-reverse;
}

svg {
  width: 100%;
  vertical-align: middle;
}

.shadow-svg {
  margin-top: -4px;
}

.shadow {
  animation: shadow var(--timing);
  rx: 50;
  ry: 10;
  opacity: 0.05;
}

.logo-holder {
  transform: translate3d(0, -30px, 0);
  animation: jump var(--timing);
}

.logo {
  transform-origin: 50% 100%;
  animation: jump-buldge var(--timing);
}

.svg-holder:nth-of-type(2) * {
  animation-delay: var(--delay);
}

.svg-holder:nth-of-type(3) * {
  animation-delay: calc(var(--delay) * 2);
}

.svg-holder:nth-of-type(4) * {
  animation-delay: calc(var(--delay) * 3);
}

.svg-holder:nth-of-type(5) * {
  animation-delay: calc(var(--delay) * 4);
}

@keyframes jump {
  43% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes jump-buldge {
  25% {
    transform: scale(1);
  }
  45% {
    transform: scale(1.35, 0.65);
  }
  60% {
    transform: scale(1);
  }
}

@keyframes shadow {
  45% {
    rx: 120;
    ry: 30;
    opacity: 0.15;
  }
}
</style>
