// @ts-expect-error we can ignore this error
import type { DocumentParameter, VariablesParameter } from '@vue/apollo-composable/dist/useQuery';
import type { NormalizedCacheObject } from '@apollo/client/core';
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client/core';

import { computed } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
import { useAsyncState } from '@vueuse/core';
import { provideApolloClient, useMutation, useQuery, useLazyQuery } from '@vue/apollo-composable';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

let apolloClientInstance: ApolloClient<NormalizedCacheObject> | null = null;

export async function getApolloClient() {
  if (apolloClientInstance) {
    return apolloClientInstance;
  }

  const { getAccessTokenSilently, logout } = useAuth0();

  const withToken = setContext(async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: import.meta.env.VITE_AUDIENCE,
        scope: 'openid email',
      },
    });

    return { accessToken };
  });

  const errorHandlerLink = onError((e) => {
    if (e.networkError && 'statusCode' in e.networkError && e.networkError.statusCode === 401) {
      logout();
    }
  });
  const httpLink = createHttpLink({ uri: import.meta.env.VITE_GRAPHQL_API_URL });
  const middlewareLink = new ApolloLink((operation, forward) => {
    const { accessToken } = operation.getContext();

    if (!forward || !accessToken) {
      return null;
    }

    operation.setContext({
      headers: {
        Authorization: `Bearer ${accessToken || ''}`,
      },
    });

    return forward(operation);
  });

  const link = ApolloLink.from([withToken, errorHandlerLink, middlewareLink, httpLink]);
  const cache = new InMemoryCache({ addTypename: false });

  apolloClientInstance = new ApolloClient({
    link,
    cache,
    connectToDevTools: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        refetchQueries: 'active',
      },
    },
  });

  return apolloClientInstance;
}

/*
 * Wrapper function for useQuery (@vue/apollo-composable) that provides an authenticated apollo client
 *
 * @param query - The gql query to execute
 * @param params - An optional object containing parameters for the query
 * @returns The result of the query, isLoading and error
 */
export function useApolloQuery<T>(
  query: DocumentParameter<unknown, T>,
  params?: VariablesParameter<unknown>,
) {
  const result = useAsyncState(
    async () => {
      const apolloClient = await getApolloClient();
      return provideApolloClient(apolloClient)(() => useQuery(query, params));
    },
    null,
    {},
  );

  return {
    result: computed(() => (result.state.value?.result.value as T) || undefined),
    isLoading: computed(() => !!result.isLoading.value || !!result.state.value?.loading.value),
    error: computed(() => result.error.value || result.state.value?.error.value),
    fetchMore: computed(() => result.state.value?.fetchMore),
    onResult: computed(() => result.state.value?.onResult),
    execute: result.execute,
  };
}

/*
 * Wrapper function for useQuery (@vue/apollo-composable) that provides an authenticated apollo client
 *
 * @param query - The gql query to execute
 * @param params - An optional object containing parameters for the query
 * @returns The result of the query, isLoading and error
 */
export function useApolloLazyQuery<T>(
  query: DocumentParameter<unknown, T>,
  params?: VariablesParameter<unknown>,
) {
  const result = useAsyncState(async () => {
    const apolloClient = await getApolloClient();
    return provideApolloClient(apolloClient)(() => useLazyQuery(query, params));
  }, null);

  return {
    result: computed(() => (result.state.value?.result.value as T) || undefined),
    isLoading: computed(() => !!result.isLoading.value || !!result.state.value?.loading.value),
    error: computed(() => result.error.value || result.state.value?.error.value),
    fetchMore: computed(() => result.state.value?.fetchMore),
    onResult: computed(() => result.state.value?.onResult),
    load: computed(() => result.state.value?.load),
  };
}

/**
 * Wrapper function for useMutation (@vue/apollo-composable) that provides an authenticated apollo client
 *
 * @param mutation - The gql mutation to execute
 * @param params - An optional object containing parameters for the mutation
 * @returns The result of the mutation, isLoading and error
 */
export function useApolloMutation<T>(
  mutation: DocumentParameter<unknown, T>,
  params?: VariablesParameter<unknown>,
) {
  const result = useAsyncState(async () => {
    const apolloClient = await getApolloClient();
    return provideApolloClient(apolloClient)(() => useMutation(mutation, params));
  }, null);

  return {
    mutate: computed(() => result.state.value?.mutate),
    isLoading: computed(() => !!result.isLoading.value || !!result.state.value?.loading.value),
    error: computed(() => result.error.value || result.state.value?.error.value),
  };
}
