import gql from 'graphql-tag';
import { EmployeeFragment } from '@/graphql/fragments/employee';

export const updateEmployeeMutation = gql`
  mutation updateEmployee($employeeId: ID!, $employee: EmployeeInput!, $validFrom: String) {
    updateEmployee(id: $employeeId, input: $employee, validFrom: $validFrom) {
      id
      ...EmployeeFragment
    }
  }
  ${EmployeeFragment}
`;
