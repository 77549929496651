import type { Employee, EmployeePermission, Permissions } from '@/types/generated/graphql';

import { createEmployeeMutation } from '@/graphql/mutations/createEmployee';
import { updateEmployeeMutation } from '@/graphql/mutations/updateEmployee';
import { employeeQuery } from '@/graphql/queries/employee';
import { employeesQuery } from '@/graphql/queries/employees';
import { fieldPermissions } from '@/graphql/queries/permissions';
import router from '@/router';
import { useApolloMutation, useApolloQuery } from '@/utils/apolloClient';
import { ApolloError } from '@apollo/client/errors';
import { defineStore } from 'pinia';
import { computed, ref, type Ref } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';

export const useEmployeeStore = defineStore('employee', () => {
  const auth0 = useAuth0();
  const showFormerEmployees: Ref<boolean> = ref(false);

  const permissionsResult = useApolloQuery<{
    employeePermissions: Permissions;
    ownPermissions: Partial<
      Record<'employee' | 'tool', Pick<EmployeePermission, 'roles' | 'scopes' | 'id'>>
    >;
  }>(
    () => (employeeId.value ? fieldPermissions : undefined),
    () => ({ employeeId: employeeId.value }),
  );
  const employeeResult = useApolloQuery<{ employee: Employee }>(
    () => (employeeId.value ? employeeQuery : undefined),
    () => ({ employeeId: employeeId.value }),
  );
  const ownEmployeeResult = useApolloQuery<{ employee: Employee }>(
    () => (ownEmployeeId.value ? employeeQuery : undefined),
    () => ({ employeeId: ownEmployeeId.value }),
  );
  const employeesResult = useApolloQuery<{ employees: Employee[]; ownPermissions: Permissions }>(
    () =>
      employeesQuery([
        'internalAvatar',
        'fullName',
        'employmentStatus',
        'email',
        'roles',
        'phonePersonal',
        'organisationData { legalEntity organisation }',
        'slack { id }',
      ]),
    {
      showFormerEmployees: computed<boolean>(() => showFormerEmployees.value ?? false),
    },
  );

  const updateMutationResult = useApolloMutation(
    () => updateEmployeeMutation,
    () => ({ employeeId: employeeId.value }),
  );

  const createMutationResult = useApolloMutation(() => createEmployeeMutation);

  const employees = computed<Employee[]>(() => employeesResult.result.value?.employees || []);
  const employee = computed<Employee | undefined>(() => employeeResult.result.value?.employee);
  const employeeId = computed(() => {
    const employeeIdParam = router.currentRoute.value.params.employeeId;
    return (
      (Array.isArray(employeeIdParam) ? employeeIdParam[0] : employeeIdParam) ??
      employee.value?.id ??
      employees.value?.find((employee) => employee.email === auth0.user.value?.email)?.id ??
      ''
    );
  });

  const ownEmployeeId = computed(
    () => employees.value?.find((employee) => employee.email === auth0.user.value?.email)?.id,
  );
  const ownEmployee = computed<Employee | undefined>(
    () => ownEmployeeResult.result.value?.employee,
  );

  const permissions = computed<EmployeePermission | undefined>(
    () => permissionsResult.result.value?.employeePermissions.employee || undefined,
  );
  const ownPermissions = computed(() => employeesResult.result.value?.ownPermissions.employee);

  const hasError = computed<boolean>(
    () =>
      !!employeesResult.error.value ||
      !!employeeResult.error.value ||
      !!updateMutationResult.error.value ||
      !!createMutationResult.error.value,
  );

  const isLoading = computed<boolean>(
    () =>
      employeesResult.isLoading.value ||
      employeeResult.isLoading.value ||
      ownEmployeeResult.isLoading.value ||
      permissionsResult.isLoading.value,
  );

  const isWaitingForPermissions = computed<boolean>(
    () => permissionsResult.isLoading.value || !permissions.value,
  );

  const isMutating = computed<boolean>(() => {
    return (
      !isLoading.value &&
      (updateMutationResult.isLoading.value || createMutationResult.isLoading.value)
    );
  });

  const isServiceUnavailable = computed(() => {
    const err = employeesResult.error.value;
    return err instanceof ApolloError && err.message === 'Failed to fetch';
  });

  function setShowFormerEmployees(value: boolean) {
    showFormerEmployees.value = value;
  }

  return {
    createEmployee: createMutationResult.mutate,
    employee,
    employeeId,
    employees,
    hasError,
    isLoading,
    isMutating,
    isServiceUnavailable,
    isWaitingForPermissions,
    ownEmployee,
    ownPermissions,
    permissions,
    setShowFormerEmployees,
    showFormerEmployees,
    updateEmployee: updateMutationResult.mutate,
  };
});
