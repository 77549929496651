import { createI18n } from 'vue-i18n';

import en from './locales/en.json';
import general from './locales/general.json';

export const i18n = createI18n({
  fallbackLocale: 'general',
  globalInjection: true,
  fallbackWarn: false,
  legacy: false,
  locale: 'en',
  messages: {
    en,
    general,
  },
  missingWarn: false,
});
