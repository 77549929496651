import gql from 'graphql-tag';
import { EmployeeFragment } from '@/graphql/fragments/employee';

export const employeeQuery = gql`
  query rawEmployee($employeeId: ID!) {
    employee(id: $employeeId) {
      id
      ...EmployeeFragment
    }
  }
  ${EmployeeFragment}
`;
