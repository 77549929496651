import gql from 'graphql-tag';
import { PermissionFragment } from '@/graphql/fragments/permission';

export const employeesQuery = (variables: string[]) => gql`
query employees($showFormerEmployees: Boolean, $ownEmployeeId: ID, $legalEntity: LegalEntity) {
    employees(showFormerEmployees: $showFormerEmployees, legalEntity: $legalEntity) {
      id
      fullName
      ${variables.join('\n')}
    }

    ownPermissions: permissions {
      employee(id: $ownEmployeeId) {
        id {
          ...PermissionFragment
        }
        _id {
          ...PermissionFragment
        }
        scopes {
          ...PermissionFragment
        }
        roles {
          ...PermissionFragment
        }
      }
    }
  }
  ${PermissionFragment}
`;
