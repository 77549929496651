<template>
  <BaseLayout>
    <div v-if="isLoading" class="flex h-full items-center"><Loader /></div>
    <ServiceUnavailableError v-else-if="employeeStore.isServiceUnavailable" />
    <RouterView v-else-if="authenticationStore.isAuthenticated" />
  </BaseLayout>
</template>

<script setup lang="ts">
import type { AnySchema } from 'yup';
import { addMethod, MixedSchema, setLocale } from 'yup';

import { computed } from 'vue';
import { RouterView } from 'vue-router';
import { useAuth0 } from '@auth0/auth0-vue';
import { useEmployeeStore } from '@/stores/employee';

import BaseLayout from '@/components/layouts/BaseLayout.vue';
import Loader from '@/components/shared/Loader.vue';
import ServiceUnavailableError from '@/pages/ServiceUnavailableError.vue';

const authenticationStore = useAuth0();
const employeeStore = useEmployeeStore();

setLocale({
  mixed: {
    required: 'This field is required',
  },
  number: {
    min: 'This field must be at least ${min}',
    max: 'This field must be at most ${max}',
  },
  string: {
    email: 'This is not a valid e-mail address',
  },
});

// Adds yup support for mulitple types
addMethod(MixedSchema, 'oneOfSchemas', function (schemas: AnySchema[]) {
  return this.test(
    'one-of-schemas',
    'Not all items in ${path} match one of the allowed schemas',
    (item) => schemas.some((schema) => schema.isValidSync(item, { strict: true })),
  );
});

const isLoading = computed(() => {
  return authenticationStore.isLoading.value || employeeStore.isLoading
});
</script>
