{
  "toastMessages": {
    "triggerDeleteSuccess": "Trigger is removed",
    "triggerDeleteError": "Trigger could not be deleted",
    "triggerDuplicationSuccess": "Trigger is duplicated",
    "triggerDuplicationError": "Trigger could not be duplicated"
  },
  "descriptions": {
    "general": "This is needed by the accountant, as well as for making reservations.",
    "emailWork": "This is your e-mail address to converse with colleagues and clients.",
    "emailPersonal": "We use this to send you your invoices.",
    "gender": "The gender you currently identify as. This information is used for the reporting of our gender equality targets.",
    "sex": "The sex assigned to you at birth, as stated on your passport. This information is sometimes necessary when making travel reservations.",
    "officialFirstName": "Your official first name as stated on your passport. This information is necessary when making travel reservations.",
    "officialLastName": "Your official last name, assigned to you at birth and as stated on your passport. This information is necessary when making travel reservations.",
    "shortcuts": {
      "saveAndCreateNew": "Hold left-shift to add another.",
      "saveAndContinue": "Hold left-shift to save and continue."
    }
  },
  "dropdownFields": {
    "absence": {
      "reason": {
        "generalLeave": "General",
        "parentalLeave": "Parental",
        "studyLeave": "Study",
        "sickLeave": "Sick"
      }
    },
    "employmentType": {
      "consultant": "Consultant",
      "internship": "Internship",
      "fixedTerm": "Fixed term",
      "permanent": "Permanent",
      "substitute": "Substitute"
    },
    "empty": "-",
    "false": "No",
    "fieldOptions": {
      "absenceActualFtePercentage": "Absence - Actual FTE Percentage",
      "absenceActualHours": "Absence - Actual Hours",
      "absenceExpectedReturnDate": "Absence - Expected Return Date",
      "absenceLongTerm": "Absence - Is Long Term",
      "absenceReasonPrettyName": "Absence - Reason",
      "absenceStartDate": "Absence - Start Date",
      "addressAddition": "Address - Addition",
      "addressCity": "Address - City",
      "addressCountry": "Address - Country",
      "addressNumber": "Address - Number",
      "addressPostalCode": "Address - Postal code",
      "addressStreet": "Address - Street",
      "age": "Age",
      "birthDate": "Birthdate",
      "contacts": "Contacts",
      "contractHours": "Contract Hours",
      "driveUrl": "Google Drive URL",
      "email": "Email",
      "emailPersonal": "Personal Email",
      "employeeId": "Employee ID",
      "employmentStatus": "Employment Status",
      "employmentType": "Employment Type",
      "employmentTypePrettyName": "Employment Type",
      "endDate": "Official End Date",
      "firstName": "First Name",
      "ftePercentage": "FTE Percentage",
      "fullAddress": "Address - Full Address",
      "fullName": "Full Name",
      "gender": "Gender",
      "iceContacts": "ICE Contacts",
      "iceContactsFirstName": "ICE Contacts - Firstname",
      "iceContactsLastName": "ICE Contacts - Lastname",
      "iceContactsRelation": "ICE Contacts - Relation",
      "iceContactsContacts": "ICE Contacts - Contact details",
      "id": "ID",
      "identityOfficialFirstName": "Identity - Official First Name",
      "identityOfficialLastName": "Identity - Official Last Name",
      "identityIdentifier": "Identity - Identifier",
      "identitySex": "Identity - Sex",
      "internalAvatar": "Internal Avatar",
      "lastName": "Last Name",
      "lastWorkingDay": "Last Working Day",
      "localPayrollId": "Local Payroll ID",
      "mentor": "Coach",
      "number": "Number",
      "organisationDataBillable": "Organisation - Billable",
      "organisationDataCity": "Organisation - City",
      "organisationDataCostCenter": "Organisation - Cost Center",
      "organisationDataCountry": "Organisation - Country",
      "organisationDataJobRole": "Organisation - Job Role",
      "organisationDataLegalEntity": "Organisation - Legal Entity",
      "organisationDataLegalEntityPrettyName": "Organisation - Legal Entity",
      "organisationDataOrganisation": "Organisation - Organisation",
      "organisationDataOrganisationSettingsCompanyCode": "Company - Code",
      "organisationDataOrganisationSettingsFteHours": "Organisation - FTE Hours",
      "organisationDataSeniority": "Organisation - Seniority",
      "organisationDataSkillTrack": "Organisation - Skill track",
      "organisationDataTitle": "Organisation - Title",
      "organisationDataTrack": "Organisation - Track",
      "organisationDataTrackPrettyName": "Organisation - Track",
      "phonePersonal": "Personal Phone Number",
      "privacy": "Privacy",
      "probationPeriod": "Probation Period",
      "probationPeriodEndDate": "End of probation period",
      "reasonForLeaving": "Reason",
      "reasonForLeavingPrettyName": "Reason",
      "relation": "Relation",
      "roles": "Roles",
      "salaryAndBenefitsActualSalary": "Actual salary",
      "salaryAndBenefitsActualSalaryAmount": "Actual salary",
      "salaryAndBenefitsActualSalaryCurrency": "Actual salary currency",
      "salaryAndBenefitsBasicSalary": "Basic salary",
      "salaryAndBenefitsBasicSalaryAmount": "Basic salary",
      "salaryAndBenefitsBasicSalaryCurrency": "Basic salary currency",
      "salaryAndBenefitsVacationEntitlement": "Yearly vacation Entitlement (days)",
      "scopes": "Scopes",
      "slackId": "Slack - ID",
      "slackImage_192": "Slack - Image URL",
      "slackUsername": "Slack - Username",
      "startDate": "Start Date",
      "trackPrettyName": "Track",
      "workPermitRequired": "Work Permit Required"
    },
    "gender": {
      "female": "Female",
      "male": "Male",
      "nonBinary": "Non-Binary",
      "notDisclosed": "Prefer not to disclose"
    },
    "organisationData": {
      "city": {
        "amsterdam": "Amsterdam",
        "chicago": "Chicago",
        "drammen": "Drammen",
        "gothenburg": "Gothenburg",
        "london": "London",
        "malmo": "Malmö",
        "newYork": "New York",
        "oslo": "Oslo",
        "rotterdam": "Rotterdam",
        "stockholm": "Stockholm",
        "shenZhenShi": "Shen Zhen Shi",
        "theHague": "The Hague",
        "theHagueAmsterdam": "The Hague / Amsterdam"
      },
      "country": {
        "denmark": "Denmark",
        "netherlands": "Netherlands",
        "norway": "Norway",
        "sweden": "Sweden",
        "usa": "USA"
      },
      "jobRole": {
        "advisory": "Advisory",
        "analyst": "Analyst",
        "associate": "Associate",
        "consultant": "Consultant",
        "expert": "Expert",
        "juniorAnalyst": "Junior Analyst",
        "juniorAssociate": "Junior Associate",
        "manager": "Manager",
        "partner": "Partner",
        "principal": "Principal",
        "seniorAdvisory": "Senior Advisory",
        "seniorConsultant": "Senior Consultant",
        "seniorExpert": "Senior Expert",
        "seniorManager": "Senior Manager",
        "seniorSpecialist": "Senior Specialist",
        "specialist": "Specialist"
      },
      "seniority": {
        "entryLevel": "Entry Level",
        "intern": "Intern",
        "level1": "Level 1",
        "level2": "Level 2",
        "level3": "Level 3",
        "level4": "Level 4",
        "level5": "Level 5",
        "level6": "Level 6"
      },
      "track": {
        "account": "Account",
        "accountAndProduction": "Account & production",
        "advancedAnalytics": "Advanced Analytics",
        "advisory": "Advisory",
        "advisoryConsulting": "Advisory Consulting",
        "analytics": "Analytics",
        "businessConsulting": "Business Consulting",
        "commercial": "Commercial",
        "content": "Content",
        "contentAndProduction": "Content & Production",
        "creative": "Creative",
        "dataQuality": "Data Quality",
        "design": "Design",
        "developmentAndManagement": "Development & Management",
        "digitalAnalytics": "Digital Analytics",
        "digitalDesign": "Digital Design",
        "digitalMedia": "Digital Media",
        "digitalX": "Digital Experience",
        "engineering": "Engineering",
        "experienceAndBehavior": "Experience & Behavior",
        "experimentation": "Experimentation",
        "expert": "Expert",
        "expertConsulting": "Expert Consulting",
        "growthPlatform": "Growth Platform",
        "insightsAndAnalytics": "Insights & Analytics",
        "management": "Management",
        "marketing": "Marketing",
        "martech": "Marketing Technology (Martech)",
        "mgmt": "Mgmt",
        "paid": "Paid",
        "physicalDesign": "Physical Design",
        "product": "Product",
        "productConsulting": "Product Consulting",
        "production": "Production",
        "projectManagement": "Project Management",
        "seo": "SEO",
        "seoAndContent": "SEO & Content",
        "strategy": "Strategy",
        "strategyAndChange": "Strategy & Change",
        "technology": "Technology",
        "webs": "Webs"
      }
    },
    "reasonForLeaving": {
      "endOfTemporaryContract": "End of temporary contract",
      "ownResignation": "Own resignation",
      "involuntary": "Involuntary",
      "redundancy": "Redundancy",
      "retired": "Retired",
      "death": "Death",
      "other": "Other",
      "involuntaryOther": "Involuntary/Other"
    },
    "roles": {
      "officeManager": "Office Manager",
      "capabilityLead": "Capability Lead",
      "coo": "Chief Operating Officer",
      "admin": "Administrator"
    },
    "sex": {
      "female": "Female",
      "male": "Male",
      "x": "Intersex",
      "notDisclosed": "Prefer not to disclose"
    },
    "true": "Yes",
    "type": {
      "consultant": "Consultant",
      "fixedTerm": "Fixed term",
      "internship": "Internship",
      "permanent": "Permanent"
    },
    "triggers": {
      "equals": "equals",
      "hasData": "has any data",
      "noData": "has no data",
      "concernsPath": "has been changed",
      "SendSlackMessage": "Send a Slack message",
      "isSameDay": "is today",
      "input": {
        "daily": "Every day at 08:00",
        "userChanged": "When a user is updated",
        "userCreated": "When a user is created"
      }
    }
  },
  "employeeFields": {
    "absence": {
      "absence": "Long-term absence",
      "longTerm": "On long term absence",
      "actualHours": "Actual hours",
      "reason": "Reason",
      "startDate": "Start date",
      "expectedReturnDate": "Expected return date",
      "actualFte": "Actual FTE",
      "actualFtePercentage": "Actual FTE Percentage"
    },
    "actualFte": "Actual FTE",
    "address": {
      "address": "Address",
      "addition": "Addition",
      "city": "City",
      "country": "Country",
      "number": "Number",
      "postalCode": "Postal code",
      "street": "Street"
    },
    "age": "Age",
    "birthDate": "Birthdate",
    "documents": {
      "openDrive": "Open link to Google Drive"
    },
    "driveUrl": "Google Drive URL",
    "email": "Email",
    "emailPersonal": "Email Personal",
    "employmentType": "Employment Type",
    "endDate": "Official End Date",
    "firstName": "First Name",
    "contractHours": "Contract Hours",
    "ftePercentage": "FTE Percentage",
    "gender": "Gender",
    "iceContacts": {
      "iceContacts": "ICE Contacts",
      "birthDate": "Birthdate",
      "contacts": {
        "value": "Contact",
        "email": "Email",
        "phone": "Phone Number"
      },
      "firstName": "Firstname",
      "lastName": "Lastname",
      "relation": "Relation"
    },
    "id": "Employment ID",
    "identity": {
      "identity": "Identity",
      "identifier": "National Identifier",
      "officialFirstName": "Official First Name",
      "officialLastName": "Official Last Name",
      "sex": "Sex"
    },
    "internalAvatar": "Internal Avatar",
    "lastName": "Last Name",
    "lastWorkingDay": "Last Working Day",
    "localPayrollId": "Local Payroll ID",
    "maritalStatus": "Marital Status",
    "nrOfCommuteDays": "Number of Commute Days",
    "mentor": "Your coach",
    "organisationData": {
      "organisationData": "Organisation Data",
      "billable": "Billable",
      "city": "City",
      "country": "Country",
      "costCenter": "Cost Center",
      "jobRole": "Job Role",
      "legalEntity": "Legal Entity",
      "organisation": "Organisation",
      "seniority": "Seniority",
      "title": "Title",
      "track": "Track",
      "skillTrack": "Skill Track"
    },
    "phonePersonal": "Personal Phone Number",
    "privacy": "Privacy",
    "probationPeriod": "Probation Period",
    "probationPeriodEndDate": "End of probation period",
    "reasonForLeaving": "Reason For Leaving",
    "roles": "Roles",
    "salaryAndBenefits": {
      "salaryAndBenefits": "Salary & Benefits",
      "actualSalary": {
        "actualSalary": "Actual Salary",
        "amount": "Actual Monthly Salary",
        "currency": "Currency"
      },
      "basicSalary": {
        "basicSalary": "Basic Salary",
        "amount": "Basic Monthly Salary",
        "currency": "Currency"
      },
      "vacationEntitlement": "Yearly vacation Entitlement (days)"
    },
    "scopes": "Scopes",
    "slack": {
      "slack": "Slack",
      "id": "ID",
      "image_72": "Image 72",
      "image_192": "Image 192",
      "username": "Username"
    },
    "startDate": "Start Date"
  },
  "formFields": {
    "scheduledEventTitle": "Scheduled changes",
    "reset": "Reset",
    "save": "Save",
    "saveAndCreateNew": "Save and new",
    "saveAndContinue": "Save and continue",
    "schedule": "Schedule"
  },
  "formToastMessageSuccess": "Successfully saved changes",
  "formToastMessageError": "Form could not be submitted",
  "createEmployeeFormToastPermissionMessage": "You are not allowed to create the new colleague (check the legal entity and role permissions)",
  "footer": {
    "slackChannel": "Team Slack Channel",
    "version": "Version"
  },
  "loading": "Loading... please wait a moment",
  "fetching": "Fetching more data, please hold...",
  "addNewColleague": "Add new colleague",
  "scheduleChanges": "Schedule changes",
  "changelog": {
    "aNewState": "a new state",
    "changes": "Changes",
    "createEntity": "created",
    "employeeNotFound": "Someone",
    "will-createEntity": "will create",
    "deleteEntity": "deleted",
    "will-deleteEntity": "will delete",
    "employeeHasScheduledChanges": "will be updated in the future. For further details of all the changes and their scheduled date, see the changelog tab.",
    "empty": "empty",
    "emptyState": "No changes to show",
    "updateEntity": "updated",
    "will-updateEntity": "will update",
    "scheduledChanges": "Scheduled Changes",
    "scheduledChangesDescription": "These changes are set to be applied in the future, but you can already preview them here.",
    "scheduledFor": "Scheduled for",
    "title": "Changelog",
    "you": "You"
  },
  "coachPage": {
    "mentorEmail": "Your coachs' e-mail",
    "mentorPhone": "Your coachs' phone number",
    "invalidOption": "This is not a valid option"
  },
  "contactPage": {
    "personalInfo": "Personal Information",
    "personalInfoDescription": "Your private details and personal information. This is used by the accountant and for sending you mail and packages.",
    "iceContactsDescription": "Details of your \"In Case of Emergency\" contacts.",
    "addContact": "Add contact",
    "deleteContact": "Delete contact"
  },
  "contractPage": {
    "contract": "Contract",
    "contractsDescription": "General details about the contract.",
    "lengthOfService": "Length of Service",
    "employment": "Employment",
    "employmentDescription": "Info applicable to the employment and service.",
    "leave": "Leave",
    "leaveDescription": "Details of the termination."
  },
  "internalServerError": {
    "title": "Internal server error",
    "description": "Our server slipped on a banana peel, but our tech team is racing to the rescue!"
  },
  "listPage": {
    "title": "Lists",
    "employeesLabel": "Employees",
    "employeesPlaceholder": "Search for colleagues...",
    "fieldsPlaceholder": "Select by...",
    "fieldsLabel": "Fields",
    "getData": "Create Table",
    "downloadCsv": "Download csv",
    "emptyState": "Create your table by selecting one or multiple fields and / or colleagues. Leave any field empty to select all options, or choose one of the table presets above."
  },
  "goToYourAccount": "Go to your account",
  "navigation": {
    "logout": "Logout",
    "myAccount": "My account"
  },
  "notFoundError": {
    "title": "Page not found",
    "description": "Oops! It seems you've requested a piece of the website that's not there anymore..."
  },
  "employeeNotFoundError": {
    "title": "Colleague not found :(",
    "description": "Oops! It seems the person you are looking for is playing hide and seek."
  },
  "serviceUnavailableError": {
    "title": "Service unavailable",
    "description": "Our servers are temporarily unavailable, but fear not! While we get our act together, make sure your network connection is working correctly. We'll rendezvous shortly!"
  },
  "pages": {
    "absence": "Long-term Absence",
    "accountData": "Account Data",
    "changelog": "Changelog",
    "coach": "Coach",
    "contact": "Contact details",
    "contract": "Contract",
    "dashboard": "Dashboard",
    "documents": "Documents",
    "employees": "Colleagues",
    "global-audit-trail": "Audit log",
    "lists": "Lists",
    "organisation": "Organisation",
    "personalData": "Personal Data",
    "roles": "Roles",
    "salary": "Salary",
    "scope": "Scope",
    "triggers": "Triggers"
  },
  "personalDataPage": {
    "identityInfo": "Identity Information",
    "identityInfoDescription": "The official stuff from your passport."
  },
  "triggers": {
    "name": "Name the trigger",
    "description": "Describe what this trigger does (optional)",
    "inputTypeLabel": "Pick a trigger",
    "inputTypes": {
      "daily": "Every day at 08:00",
      "userChanged": "When a user is updated",
      "userCreated": "When a user is created"
    },
    "dailyConfigText": "Day at 08:00",
    "matchCriteriaText": "And it matches one of these criteria",
    "addFilter": "Add filter",
    "addCriteria": "Add criteria",
    "addAction": "Add action",
    "then": "Then:",
    "saveTrigger": "Save trigger",
    "actionTypes": {
      "SendSlackMessage": "Send a Slack message"
    },
    "actionTo": "To:",
    "actionMessage": "Message:",
    "addRecipient": "Add recipient",
    "comparatorTypes": {
      "after": "is after",
      "before": "is before",
      "concernsPath": "has been changed",
      "equals": "equals",
      "hasData": "has any data",
      "isSameDay": "is today",
      "noData": "has no data"
    },
    "equals": "equals",
    "hasData": "has any data",
    "noData": "has no data",
    "concernsPath": "concerns path"
  },
  "errors": {
    "noSlackId": "No Slack ID found for this user"
  },
  "alerts": {
    "employmentStatusTerminated": {
      "title": "Heads up!",
      "text": "This colleague is no longer working at the company."
    },
    "employmentStatusNotStarted": {
      "title": "Heads up!",
      "text": "This colleague has not started yet."
    }
  },
  "organisationSettings": {
    "companyCode": "Company Code",
    "companyCodeAdditionalInfo": "This code will be automatically updated when the new Legal Entity is saved."
  }
}
