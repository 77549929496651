import gql from 'graphql-tag';
import { PermissionFragment } from '@/graphql/fragments/permission';

/**
 * This query fetches two things:
 * 1. The permissions the logged-in-user has on the requested employee
 * 2. The roles and scopes of the logged-in-user
 */
export const fieldPermissions = gql`
  query fieldPermissions($employeeId: ID) {
    employeePermissions: permissions {
      employee(id: $employeeId) {
        _id {
          ...PermissionFragment
        }
        absence {
          ...PermissionFragment
        }
        address {
          ...PermissionFragment
        }
        age {
          ...PermissionFragment
        }
        birthDate {
          ...PermissionFragment
        }
        driveUrl {
          ...PermissionFragment
        }
        email {
          ...PermissionFragment
        }
        emailPersonal {
          ...PermissionFragment
        }
        employeeId {
          ...PermissionFragment
        }
        employmentType {
          ...PermissionFragment
        }
        endDate {
          ...PermissionFragment
        }
        firstName {
          ...PermissionFragment
        }
        contractHours {
          ...PermissionFragment
        }
        ftePercentage {
          ...PermissionFragment
        }
        fullName {
          ...PermissionFragment
        }
        gender {
          ...PermissionFragment
        }
        iceContacts {
          ...PermissionFragment
        }
        id {
          ...PermissionFragment
        }
        identity {
          ...PermissionFragment
        }
        internalAvatar {
          ...PermissionFragment
        }
        lastName {
          ...PermissionFragment
        }
        lastWorkingDay {
          ...PermissionFragment
        }
        localPayrollId {
          ...PermissionFragment
        }
        mentor {
          ...PermissionFragment
        }
        organisationData {
          ...PermissionFragment
        }
        phonePersonal {
          ...PermissionFragment
        }
        privacy {
          ...PermissionFragment
        }
        probationPeriod {
          ...PermissionFragment
        }
        probationPeriodEndDate {
          ...PermissionFragment
        }
        reasonForLeaving {
          ...PermissionFragment
        }
        roles {
          ...PermissionFragment
        }
        salaryAndBenefits {
          ...PermissionFragment
        }
        scopes {
          ...PermissionFragment
        }
        slack {
          ...PermissionFragment
        }
        startDate {
          ...PermissionFragment
        }
      }
    }
  }
  ${PermissionFragment}
`;
