<template>
  <li
    :key="targetRoute.name?.toString()"
    class="shrink-0 px-4 py-2 hover:underline"
    :class="{ 'font-semibold': current }"
  >
    <RouterLink :to="targetRoute.href" class="flex w-full gap-x-3 rounded-md">
      <component
        v-if="targetRoute.meta.icon"
        :is="targetRoute.meta.icon"
        class="h-6 w-6"
        aria-hidden="true"
      />
      {{ t(`pages.${targetRoute.name as string}`) }}
      <Typography
        v-if="count"
        variant="caption"
        is="span"
        aria-hidden="true"
        class="ml-auto grid h-6 w-6 place-content-center whitespace-nowrap rounded-full bg-primary text-on-primary"
      >
        {{ count }}
      </Typography>
    </RouterLink>
  </li>
</template>

<script lang="ts" setup>
import type { RouteRecord } from '@/types';

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useEmployeeStore } from '@/stores/employee';
import { resolveRoute } from '@/utils/router';

import Typography from '@/components/shared/Typography.vue';

type Props = {
  route: RouteRecord;
  current: boolean;
  count?: number;
};

const { t } = useI18n();

const props = defineProps<Props>();

const employeeStore = useEmployeeStore();

const targetRoute = computed(() => resolveRoute(props.route, employeeStore.employee?.id));
</script>
