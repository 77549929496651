import { onMounted, onUnmounted, ref } from 'vue';
import { getUserOS } from '@/utils';

export function useOmnisearchShortcut() {
  const os = ref(getUserOS());
  const active = ref(false);

  function shortcutListener(event: KeyboardEvent) {
    if (!os.value) {
      return;
    }

    if (os.value === 'MacOS' && event.key === 'k' && event.metaKey) {
      setActive();
    }

    if (os.value === 'Windows' && event.key === 'k' && event.ctrlKey) {
      setActive();
    }

    if (active.value && event.key === 'Escape') {
      setActive(false);
    }
  }

  function setActive(target?: boolean) {
    active.value = target ?? !active.value;
  }

  onMounted(() => window.addEventListener('keydown', shortcutListener));
  onUnmounted(() => window.removeEventListener('keydown', shortcutListener));

  return { active, setActive };
}
