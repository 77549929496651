import type { Route, RouteParams, RouteRecord } from '@/types';
import router from '@/router';

export function getStaticRoutes(): RouteRecord[] {
  return router.options.routes
    .flatMap((parent) => {
      if (parent.children) {
        const children = parent.children.map((child) => ({
          ...child,
          path: `${parent.path}/${child.path}`,
        }));
        return [parent, ...children];
      }

      return parent;
    })
    .map((r) => ({
      ...r,
      name: r.name?.toString() ?? r.path,
      meta: r.meta ?? {},
    }));
}

export function resolveRoute(route: RouteRecord, employeeId?: string): Route {
  const params: RouteParams = {};

  if (route.path?.includes(':employeeId')) {
    if (!employeeId) {
      throw new Error('Employee ID is required for this route');
    }

    params.employeeId = employeeId;
  }

  return router.resolve({ name: route.name, params });
}
