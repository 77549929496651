import './global.css';
import packageJson from '../package.json';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createAuth0 } from '@auth0/auth0-vue';

import App from './App.vue';
import { i18n } from './i18n';
import router from './router';
import rg4js from 'raygun4js';

rg4js('apiKey', 'KhEA1t9RwstZU8JELgscQ');
rg4js('enableCrashReporting', true);
rg4js('saveIfOffline', true);
rg4js('setVersion', packageJson.version);
rg4js('options', {
  ignoreAjaxAbort: true,
  ignore3rdPartyErrors: true,
  debugMode: false,
  excludedHostnames: ['localhost'],
});

const app = createApp(App);

app.config.errorHandler = function (err, vm, info) {
  rg4js('send', {
    error: err,
    customData: [{ info: info }],
  });
};

app.use(createPinia());
app.use(i18n);
app.use(router);
app.use(
  createAuth0({
    domain: import.meta.env.VITE_DOMAIN,
    clientId: import.meta.env.VITE_CLIENT_ID,
    cacheLocation: 'localstorage',
    authorizationParams: {
      audience: import.meta.env.VITE_AUDIENCE,
      redirect_uri: window.location.origin,
    },
  }),
);

app.mount('#app');
