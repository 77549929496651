/**
 * Filters an array based on a specified limit and a filtering condition.
 *
 * @template T
 * @param {T[]} array - The array to filter.
 * @param {number} limit - The maximum number of results to include in the filtered array.
 * @param {(value: T) => boolean} compareFn - A callback function that defines the filtering condition.
 * @returns {T[]} An array containing elements from the original array that satisfy the filtering condition, limited to the specified count.
 *
 * @example
 * const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
 * const filteredNumbers = filterWithLimit(numbers, 3, (num) => num > 5);
 * // filteredNumbers is [6, 7, 8]
 */
export function filterWithLimit<T>(
  array: T[],
  limit: number,
  compareFn: (value: T) => boolean,
): T[] {
  const results = [];
  for (const item of array) {
    if (results.length >= limit) {
      break;
    }

    if (compareFn(item)) {
      results.push(item);
    }
  }

  return results;
}
