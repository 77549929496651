<template>
  <ComboBox
    v-if="!employeeStore.isLoading"
    label="Search"
    :search="to === 'all' ? 'omni' : true"
    :only-sr-label="true"
    :options="searchOptions"
    :placeholder="placeholder"
    @update:modelValue="(value) => value && router.push(value)"
    class="hidden lg:block"
  >
    <template v-slot:iconLeft>
      <MagnifyingGlassIcon class="h-5 w-5" aria-hidden="true" />
    </template>
    <template v-if="to === 'all' && keyShortcut" v-slot:iconRight>
      <kbd class="inline-flex items-center rounded border p-1 font-sans">
        {{ keyShortcut }}
      </kbd>
    </template>
  </ComboBox>
</template>

<script lang="ts" setup>
import type { Employee } from '@/types/generated/graphql';
import type { Route, SearchOption } from '@/types';

import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useEmployeeStore } from '@/stores/employee';
import { useUserStore } from '@/stores/user';
import {
  getStaticRoutes,
  getUserOS,
  mapEmployeeToOption,
  mapRouteToOption,
  resolveRoute,
} from '@/utils';
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid';

import ComboBox from '@/components/shared/ComboBox.vue';
import { useI18n } from 'vue-i18n';

type Props = {
  to?: 'page' | 'all';
  placeholder?: string;
};

const props = withDefaults(defineProps<Props>(), { to: 'all' });

const OS = getUserOS();
const keyShortcut = ref(getKeyboardShortcut());

const employeeStore = useEmployeeStore();
const userStore = useUserStore();
const router = useRouter();
const { t } = useI18n();

const searchOptions = computed(() => {
  const options: SearchOption[] = [];

  if (employeeStore.employees && props.to === 'all') {
    options.push(...employeeStore.employees.map(mapEmployeeToOption));
  }

  if (props.to === 'page' || props.to === 'all') {
    options.push(
      ...getStaticRoutes()
        .filter((route) => route.meta.searchTags)
        .map((r) => resolveRoute(r, employeeStore.employee?.id ?? userStore.email))
        .map(mapRouteToOption),
    );
  }

  return options;
});

function getKeyboardShortcut(): '⌘K' | 'Ctrl + K' | undefined {
  if (OS === 'MacOS') {
    return '⌘K';
  }

  if (OS === 'Windows') {
    return 'Ctrl + K';
  }
}
</script>
