<template>
  <component
    :is="link ? 'RouterLink' : 'div'"
    :to="link ? `/employee/${email}/` : undefined"
    class="group flex items-center text-body-small"
  >
    <img
      v-if="profilePicture"
      :class="[$attrs.class, sizeClass]"
      class="aspect-square rounded-full object-cover"
      :src="profilePicture"
      alt=""
      referrerpolicy="no-referrer"
    />
    <div
      v-else
      :class="[$attrs.class, sizeClass]"
      class="flex aspect-square select-none items-center justify-center rounded-full bg-subtle object-cover text-on-subtle"
    >
      {{ abbreviation }}
    </div>
    <span v-if="label" class="sr-only">{{ label }}</span>
    <template v-if="showName !== 'never'">
      <div
        class="ml-4"
        :class="[showName === 'desktop' ? 'hidden lg:inline' : 'inline']"
        aria-hidden="true"
      >
        <span
          class="block text-body leading-5"
          :class="{
            'group-hover:underline': hoverEffect,
            'font-semibold': current,
            'leading-5': subtitle,
          }"
        >
          {{ name }}
        </span>
        <span
          v-if="subtitle"
          class="block text-body-small font-normal leading-5 text-on-background/70"
        >
          {{ subtitle }}
        </span>
      </div>
    </template>
  </component>
</template>

<script lang="ts" setup>
import type { Maybe } from '@/types/generated/graphql';
import { computed } from 'vue';

type Props = {
  name: string;
  subtitle?: string;
  size?: 'sm' | 'md' | 'lg';
  label?: string;
  profilePicture?: Maybe<string>;
  showName?: 'never' | 'desktop' | 'always';
  hoverEffect?: boolean;
} & LinkProps;

type LinkProps =
  | {
      current: boolean;
      link: true;
      email: string;
    }
  | {
      link?: false;
      current?: false;
      email?: undefined;
    };

const props = withDefaults(defineProps<Props>(), {
  size: 'md',
  showName: 'never',
});

defineOptions({
  inheritAttrs: false,
});

const sizeClass = computed(() => {
  switch (props.size) {
    case 'sm':
      return 'w-6 h-6 text-body-smaller';
    case 'md':
      return 'w-10 h-10 text-body';
    case 'lg':
      return 'w-16 h-16 text-heading-3';
    default:
      return '';
  }
});

const abbreviation = computed(() =>
  props.name
    .split(' ')
    .map((w) => w.charAt(0))
    .join(''),
);
</script>
