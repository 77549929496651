import type { OperatingSystem } from '@/types';

export function getUserOS(): OperatingSystem | undefined {
  if (!('navigator' in window)) {
    return undefined;
  }

  const userAgent = navigator.userAgent;

  if (userAgent.search('Windows') !== -1) {
    return 'Windows';
  }

  if (userAgent.search('Mac') !== -1) {
    return 'MacOS';
  }

  if (userAgent.search('X11') !== -1 && !(userAgent.search('Linux') !== -1)) {
    return 'Unix';
  }

  if (userAgent.search('Linux') !== -1 && userAgent.search('X11') !== -1) {
    return 'Linux';
  }
}
