<template>
  <li
    :class="[
      !active && 'bg-surface text-on-surface',
      active && 'bg-primary text-on-primary',
      hasAvatar(option) || hasIcon(option) ? 'pl-3' : 'pl-0',
      'relative cursor-pointer select-none py-2 pr-9',
    ]"
  >
    <div class="flex items-center">
      <Avatar
        v-if="hasAvatar(option)"
        :profile-picture="option.avatar"
        :name="option.name"
        class="h-10 w-10 flex-shrink-0"
      />
      <component
        v-if="hasIcon(option)"
        :is="option.icon"
        class="h-10 w-10 p-2"
        aria-hidden="true"
      />
      <div>
        <span
          :class="[
            selected ? 'font-semibold' : 'font-normal',
            avatarOption.subtitle ? 'leading-5' : '',
            'ml-3 block truncate',
          ]"
        >
          {{ label }}
        </span>

        <span
          v-if="avatarOption.subtitle"
          class="ml-3 block truncate text-body-small font-normal leading-5"
          :class="[active ? 'text-on-primary' : 'text-on-surface/70']"
        >
          {{ avatarOption.subtitle }}
        </span>
      </div>
    </div>

    <span
      v-if="selected"
      :class="[
        active ? 'text-on-primary' : 'text-primary',
        'absolute inset-y-0 right-0 flex items-center pr-4',
      ]"
    >
      <CheckIcon class="h-5 w-5" aria-hidden="true" />
    </span>
  </li>
</template>

<script lang="ts" setup>
import type { DropdownOption, OptionWithAvatar, SearchOption } from '@/types';

import { computed } from 'vue';
import { formatOptionLabel, hasAvatar, hasIcon } from '@/utils';

import Avatar from '@/components/shared/Avatar.vue';
import { CheckIcon } from '@heroicons/vue/20/solid';

type Props = {
  active: boolean;
  fieldName?: string;
  option: DropdownOption | SearchOption;
  selected: boolean;
  translate: boolean;
};

const props = defineProps<Props>();

const label = computed(() =>
  props.translate ? formatOptionLabel(props.option.value, props.fieldName) : props.option.value,
);

const avatarOption = props.option as OptionWithAvatar;
</script>
