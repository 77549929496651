import dayjs, { type Dayjs } from 'dayjs';
import dayjsDuration from 'dayjs/plugin/duration';
import dayjsRelativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(dayjsDuration);
dayjs.extend(dayjsRelativeTime);

export function ageFromDateOfBirthday(dateOfBirth: any): number {
  if (!dateOfBirth) {
    return 0;
  }

  const today = new Date();
  const birthDate = new Date(dateOfBirth);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}

export function getLengthOfService(
  startDate: string | Date | Dayjs,
  endDate?: string | Date | Dayjs | null,
): string {
  if (!startDate || dayjs(startDate).isAfter(new Date())) {
    // not yet started
    return '';
  }

  if (!endDate) {
    // the data provide is sometime null and sometimes undefined
    endDate = new Date();
  }

  const diffMonths = dayjs(endDate).diff(dayjs(startDate), 'months');
  const years = Math.floor(diffMonths / 12);
  const months = diffMonths % 12;

  const parts: string[] = [];

  // Years
  if (years > 0) {
    parts.push(`${years} years`);
  }

  // Months
  if (years === 0 && months === 0) {
    parts.push(`less than 1 month`);
  } else if (months === 1) {
    parts.push(`1 month`);
  } else if (months > 1) {
    parts.push(`${months} months`);
  }

  return parts.join(' and ');
}

export function setDateToInputFormat(date: Date): string {
  const timezoneOffset = date.getTimezoneOffset();
  const offsetDate = new Date(date.getTime() - timezoneOffset * 60 * 1000);
  return offsetDate.toISOString().split('T')[0];
}

export function isSameDay(a: Date, b: Date): boolean {
  return dayjs(a).isSame(b, 'day');
}

/**
 * Checks if a given date `dateB` occurs on an earlier day than `dateA`, ignoring the time component.
 *
 * @param {Date} dateA - The reference date.
 * @param {Date} dateB - The date to compare against the reference date.
 * @returns {boolean} `true` if `dateB` is on an earlier day than `dateA`, `false` otherwise.
 *
 * @example
 * const dateA = new Date('2023-08-29T15:00:00');
 * const dateB = new Date('2023-08-28T10:00:00');
 * console.log(isPastDate(dateA, dateB)); // Output: true
 */
export function isBeforeDate(dateA: Date | string, dateB: Date | string): boolean {
  const a = new Date(dateA);
  const b = new Date(dateB);

  // Set hours, minutes, seconds, and milliseconds to 0 to compare only days
  a.setHours(0, 0, 0, 0);
  b.setHours(0, 0, 0, 0);

  return b < a;
}
