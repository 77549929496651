<template>
  <div class="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
    <div class="text-center">
      <Typography is="p" variant="subtitle" weight="medium" class="text-secondary">
        {{ errorCode }}
      </Typography>
      <Typography is="h1" variant="heading-1" weight="bold" class="mt-4 tracking-tight">
        {{ t(`${props.errorPage}.title`) }}
      </Typography>
      <Typography is="p" variant="body" class="mt-6 leading-7">
        {{ t(`${props.errorPage}.description`) }}
      </Typography>
      <div class="mt-10 flex items-center justify-center gap-x-6">
        <Link
          v-if="userStore.ownEmployeeId"
          is="RouterLink"
          :to="`/employee/${userStore.ownEmployeeId}/`"
          :label="t('goToYourAccount')"
        />
        <button
          v-else
          type="button"
          class="text-base -mx-3 block rounded-lg bg-secondary px-3 py-2 font-semibold leading-7 text-on-primary hover:bg-primary"
          @click="logout"
        >
          {{ t('navigation.logout') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import Link from '@/components/shared/Link.vue';
import Typography from '@/components/shared/Typography.vue';
import { useUserStore } from '@/stores/user';

type Props = {
  errorPage:
    | 'notFoundError'
    | 'internalServerError'
    | 'serviceUnavailableError'
    | 'employeeNotFoundError';
};

const props = defineProps<Props>();

const { t } = useI18n();
const userStore = useUserStore();

const errorCode = computed(() => {
  switch (props.errorPage) {
    case 'notFoundError':
    case 'employeeNotFoundError':
      return 404;
    case 'internalServerError':
      return 500;
    case 'serviceUnavailableError':
      return 503;
    default:
      throw new Error('Unknown errorPage type');
  }
});

function logout() {
  userStore.logout({ logoutParams: { returnTo: window.location.origin } });
}
</script>
