<template>
  <Combobox
    as="div"
    :model-value="multiple ? selectedItems : selectedItem"
    @update:model-value="updateValue"
    :name="name"
    :nullable="!required"
    ref="combobox"
    :multiple="multiple"
  >
    <ComboboxLabel
      class="mb-2 block text-mobile-body font-medium text-on-background sm:text-body"
      :class="{ 'sr-only': onlySrLabel }"
    >
      {{ label }}
    </ComboboxLabel>

    <div v-if="multiple" class="pb-2" :class="renderSelectedOptions.length === 0 && 'invisible'">
      {{ renderSelectedOptions || label }}
    </div>
    <div class="relative text-mobile-body sm:text-body">
      <div class="relative flex items-center">
        <ComboboxInput
          class="block w-full rounded-md border-0 bg-surface py-1.5 text-mobile-body text-on-surface shadow-sm ring-1 ring-inset ring-on-background/30 placeholder:text-on-surface/70 read-only:bg-background read-only:text-opacity-70 read-only:shadow-none disabled:opacity-50 sm:text-body"
          :class="[
            inline ? 'rounded-l-md' : 'rounded-md',
            $slots.iconRight ? 'pr-16' : 'pr-3',
            $slots.iconLeft ? 'pl-8' : hasAvatar(selectedItem) ? 'pl-11' : 'pl-3',
          ]"
          @change="query = $event.target.value"
          :disabled="$attrs.disabled || $attrs.readonly"
          :displayValue="(option) => (option as SearchOption)?.value ?? ''"
          :placeholder="placeholder"
          ref="input"
        />
        <ComboboxButton
          v-if="$slots.iconLeft"
          class="absolute inset-y-0 left-0 flex items-center py-2 pl-1.5 text-caption text-on-surface/50"
        >
          <slot name="iconLeft" />
        </ComboboxButton>
        <ComboboxButton
          v-if="$slots.iconRight"
          class="absolute inset-y-0 right-0 flex items-center py-2 pr-1.5 text-caption text-on-surface/50"
        >
          <slot name="iconRight" />
        </ComboboxButton>
      </div>

      <Avatar
        v-if="selectedItem && hasAvatar(selectedItem) && !search"
        class="absolute inset-y-0 left-3 my-auto"
        size="sm"
        :name="selectedItem.name"
        :profile-picture="selectedItem.avatar"
        :subtitle="selectedItem.subtitle"
      />

      <component
        v-if="selectedItem && hasIcon(selectedItem) && !search"
        :is="selectedItem.icon"
        class="absolute inset-y-0 left-3 my-auto h-6 w-6"
      />

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ComboboxOptions
          v-if="filteredOptions.length > 0"
          class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-surface py-1 shadow-lg focus:outline-none focus:ring-1 focus:ring-focus"
          :class="listClass"
        >
          <ComboboxOption
            as="template"
            v-for="option in filteredOptions"
            :key="option.id?.toString() ?? uuid()"
            :value="option"
            v-slot="{ active, selected }"
          >
            <DropdownItem
              :option="option"
              :active="active"
              :selected="selected"
              :translate="false"
            />
          </ComboboxOption>
        </ComboboxOptions>
        <ComboboxOptions
          v-else
          class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-surface py-1 text-subtle shadow-lg focus:outline-none focus:ring-1 focus:ring-focus"
          :class="listClass"
        >
          <DropdownItem
            class="!cursor-default"
            :option="{ value: 'No result found' }"
            :active="active"
            :selected="false"
            :translate="false"
          />
        </ComboboxOptions>
      </transition>
    </div>
  </Combobox>
</template>

<script setup lang="ts">
import type { SearchOption } from '@/types';

import { deburr } from 'lodash';
import { v4 as uuid } from 'uuid';
import { computed, ref, watch } from 'vue';
import { hasAvatar, hasIcon, filterWithLimit } from '@/utils';
import { useOmnisearchShortcut } from '@/composables/omnisearch';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/vue';

import DropdownItem from '@/components/shared/DropdownItem.vue';
import Avatar from '@/components/shared/Avatar.vue';
import type { Employee } from '@/types/generated/graphql';

type Props = {
  inline?: boolean;
  label: string;
  listClass?: string;
  modelValue?: string | SearchOption[] | Partial<Employee>;
  name?: string;
  onlySrLabel?: boolean;
  search?: 'omni' | boolean;
  options: SearchOption[];
  placeholder?: string;
  required?: boolean;
  multiple?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  multiple: false,
});
const emit = defineEmits(['update:modelValue']);

const combobox = ref<InstanceType<typeof Combobox> | null>(null);
const input = ref<InstanceType<typeof ComboboxInput> | null>(null);
const { active } = useOmnisearchShortcut();

const query = ref('');
const selectedItem = ref(setSelectedItem(props.modelValue));
const selectedItems = ref<SearchOption[]>([]);
const renderSelectedOptions = computed(() =>
  selectedItems.value.map((option) => option.value).join(', '),
);

function updateValue(value: SearchOption | SearchOption[]) {
  if (props.multiple && value instanceof Array) {
    selectedItems.value = value;
  } else if (!(value instanceof Array)) {
    selectedItem.value = value;
  }

  // Emit either the id or the value of the selected item
  !props.multiple && !(value instanceof Array)
    ? emit('update:modelValue', value.id ?? value.value ?? value)
    : emit('update:modelValue', value);
}

function setSelectedItem(
  selectedValue: SearchOption[] | string | null | undefined | Partial<Employee>,
) {
  if (!selectedValue) {
    return undefined;
  }
  // Handle object values (e.g. Employees)
  const matchWithId = props.options.find((option) => option.id === selectedValue);
  // Handle string values (e.g. CountryCode)
  const matchWithValue = props.options.find((option) => option.value === selectedValue);
  // Handle partial Employee objects (e.g. Coach)
  const matchAsEmployee = props.options.find(
    (option) => option.value === (selectedValue as Partial<Employee>).fullName,
  );

  return matchWithId ?? matchWithValue ?? matchAsEmployee ?? undefined;
}

watch(
  () => props.modelValue,
  (newValue) => (selectedItem.value = setSelectedItem(newValue)),
);

watch(active, (newValue) => {
  if (newValue && props.search === 'omni') {
    input.value?.$el.focus();
    input.value?.$el.select();
    return;
  }

  input.value?.$el.blur();
});

const filteredOptions = computed(() => {
  const normalizedQuery = deburr(query.value.toLowerCase());
  return filterWithLimit(props.options, 10, (option) => {
    const searchValues = option.searchTags ? [...option.searchTags, option.value] : [option.value];
    return searchValues.some((value) => {
      if (!value) {
        return false;
      }

      const searchValue = deburr(value.toLowerCase());
      return searchValue.includes(normalizedQuery);
    });
  });
});
</script>
