export function sortByName(arr: any[], identifier: string = 'name') {
  return arr.sort(function (a, b) {
    const nameOfPersonA = a[identifier].toLowerCase();
    const nameOfPersonB = b[identifier].toLowerCase();

    if (nameOfPersonA < nameOfPersonB) {
      return -1;
    }
    if (nameOfPersonA > nameOfPersonB) {
      return 1;
    }
    return 0;
  });
}
