import { type ToastType, useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

const toast = useToast();

export function triggerToast(message: string, type: ToastType) {
  toast.open({
    message,
    type,
    position: 'bottom',
  });
}
